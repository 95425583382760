<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增规格模板':'修改规格模板'" :close-on-click-modal="false" @closed="cancel">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="110px" v-if="form">
        <el-form-item label="模板名称" prop="name">
          <el-input v-model.trim="form.name" :maxlength="25" />
        </el-form-item>
        <el-form-item label="规格" prop="specs">
          <div>
            <el-button @click.stop="handleAdd()" size="mini" type="primary">添加</el-button>
            <span style="font-size:12px">多个规格值以“|”符号分开（不包含引号），示例：红色|白色</span>
          </div>
          <el-table :data="form.specs" size="mini" border>
            <el-table-column prop="name" label="规格名" min-width="20">
              <template slot-scope="scope">
                <el-input v-model.trim="scope.row.name" :maxlength="50" />
              </template>
            </el-table-column>
            <el-table-column prop="value" label="规格值" min-width="50">
              <template slot-scope="scope">
                <el-input v-model.trim="scope.row.value" :maxlength="100" />
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="60">
              <div class="row-commands" slot-scope="scope">
                <el-button type="text" size="mini" @click.stop="handleRemove(scope.row)">移除</el-button>
              </div>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/specTemplate";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    var validateSpec = (rule, value, callback) => {
      let err = null;
      for (let i = 0, l = value.length; i < l; i++) {
        let item = value[i];
        if (!item.name) {
          err = new Error(`请输入第${i + 1}行的规格名`);
          break;
        } else {
          item.value = (item.value || "")
            .replace(/\|{2,}/gi, "|")
            .replace(/(^\|)|(\|$)/gi, "");
          if (!item.value) {
            err = new Error(`请输入第${i + 1}行规格值`);
            break;
          }
        }
      }
      if (err) callback(err);
      else callback();
    };
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: {
        name: null,
        specs: []
      },
      rules: {
        name: [
          { required: "true", message: "模板名称为必填项", trigger: "blur" }
        ],
        specs: [
          { required: "true", message: "规格必填项", trigger: "blur" },
          { type: "array", min: 1, message: "至少添加一个规格" },
          { validator: validateSpec, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    handleRemove(val) {
      let index = this.form.specs.indexOf(val);
      this.form.specs.splice(index, 1);
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    handleAdd() {
      this.form.specs.push({ name: "", value: "" });
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          let d = this.handleData();
          add(d)
            .then(res => {
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          let d = this.handleData();
          edit(d)
            .then(res => {
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    handleData() {
      let d = JSON.parse(JSON.stringify(this.form));
      let specName = "";
      d.specs.forEach(s => {
        specName += s.name + "|";
      });
      d.specsName = specName.substring(0, specName.length - 1);
      d.specs = JSON.stringify(d.specs);
      return d;
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      if (form) {
        form.specs = JSON.parse(form.specs);
      }
      this.form = form || {
        name: null,
        specs: []
      };
    }
  }
};
</script>

